<template>
  <v-container fluid class="thankyou__container">
    <v-list-item-content></v-list-item-content>
    <v-data-iterator
      :items="donations.donationDetails"
      :headers="headers"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-card v-for="item in props.items" :key="item.name">
          <v-row>
            <v-col>
              <v-list-item>
                <v-card-title>Donor Name:</v-card-title>
                <v-card-title class="align-end">{{
                  item.donor_name
                }}</v-card-title>
              </v-list-item>
            </v-col>
          </v-row>
          <b-alert
            v-model="showdownloading"
            variant="success"
            dismissible
            data-dismiss="alert"
          >
            Downloading Initiated
          </b-alert>
          <b-alert v-model="showEmailSending" variant="success" dismissible>
            Email Sending Initiated
          </b-alert>

          <v-row align="center">
            <v-col class="text-center" cols="12" sm="6" lg="3">
              <div class="my-2">
                <b-button
                  pill
                  variant="outline-secondary"
                  v-on:click="backToDonations"
                  >BACK</b-button
                >
              </div>
            </v-col>
            <v-col class="text-center" cols="12" sm="6" lg="3">
              <div class="my-2">
                <b-button
                  class="m-1"
                  v-show="item.status == 'success'"
                  pill
                  variant="success"
                  v-on:click="receipt"
                  :loading="loading"
                  :disabled="loading"
                  color="info"
                  @click="(loader = 'loading'), (showdownloading = true)"
                  >RECEIPT</b-button
                >
              </div>
            </v-col>
            <v-col class="text-center" cols="12" sm="6" lg="3">
              <div class="my-2">
                <b-button
                  v-show="item.status == 'success'"
                  pill
                  variant="info"
                  v-on:click="resend"
                  :loading="loading"
                  :disabled="loading"
                  color="info"
                  @click="(loader = 'loading'), (showEmailSending = true)"
                  >RESEND EMAIL</b-button
                >
              </div>
            </v-col>
            <v-col class="text-center" cols="12" sm="6" lg="3">
              <div class="my-2">
                <b-button
                  v-show="item.status == 'success'"
                  pill
                  variant="primary"
                  v-on:click="declaration"
                  :loading="loading"
                  :disabled="loading"
                  color="info"
                  @click="(loader = 'loading'), (showdownloading = true)"
                  >DELARATION</b-button
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-data-iterator>

    <v-data-iterator
      :items="donations.donationDetails"
      :headers="headers"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.name">
            <v-card>
              <v-card-title class="subheading font-weight-bold"
                >Contribution Details
              </v-card-title>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>Donation Id:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.donation_id
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Project Name:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.project_name
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Donation Amount:</v-list-item-content>
                <v-list-item-content class="align-end"
                  ><span>{{
                    item.amount | currency
                  }}</span></v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Status:</v-list-item-content>
                <v-list-item-content class="align-end">
                  <b-alert
                    v-if="item.status == 'success'"
                    variant="success"
                    class="mb-2"
                    show
                  >
                    {{ item.status }}
                  </b-alert>
                  <b-alert v-else variant="danger" class="mb-2" show>
                    {{ item.status }}</b-alert
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Date:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.created_at
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Receipt Id:</v-list-item-content>
                <v-list-item-content
                  v-if="item.receipt_id != null"
                  class="align-end"
                  >{{ item.receipt_id }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Transaction Id:</v-list-item-content>
                <v-list-item-content
                  v-if="item.transaction_id != null"
                  class="align-end"
                  >{{ item.transaction_id }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <v-data-iterator
      :items="donations.donationDetails"
      :headers="headers"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.name">
            <v-card>
              <v-card-title class="subheading font-weight-bold"
                >Personal Details
              </v-card-title>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>Donor Name:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.donor_name
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Gender:</v-list-item-content>
                <v-list-item-content
                  v-if="item.gender != null"
                  class="align-end"
                  >{{ item.gender }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Date of Birth:</v-list-item-content>
                <v-list-item-content
                  v-if="item.dob != null"
                  class="align-end"
                  >{{ item.dob }}</v-list-item-content
                >
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Address:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.address
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>City:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.city
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>District:</v-list-item-content>
                <v-list-item-content
                  v-if="item.district != null"
                  class="align-end"
                  >{{ item.district }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Postal Code:</v-list-item-content>
                <v-list-item-content
                  v-if="item.postal_code != null"
                  class="align-end"
                  >{{ item.postal_code }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>State:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.state
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Country:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.country
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>PAN:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.pan_no
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Mobile No:</v-list-item-content>
                <v-list-item-content class="align-end">{{
                  item.phone
                }}</v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Email:</v-list-item-content>
                <v-list-item-content
                  v-if="item.email != null"
                  class="align-end"
                  >{{ item.email }}</v-list-item-content
                >
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Referred By:</v-list-item-content>
                <v-list-item-content
                  v-if="item.referred_by != null"
                  class="align-end"
                  >{{ item.referred_by }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end"
                  >NA</v-list-item-content
                >
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";
Vue.filter("currency", function(value) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

export default {
  name: "donationView",
  data() {
    return {
      loader: null,
      loading: false,
      showdownloading: false,
      showEmailSending: false,
      donations: [],
      receiptId: "",
      //initialise data to headers
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "name"
        },

        {
          value: "donation_id"
        },
        {
          value: "project_name"
        },
        {
          value: "amount"
        },
        {
          value: "status"
        },
        {
          value: "created_at"
        },
        {
          value: "receipt_id"
        },
        {
          value: "donor_name"
        },
        {
          value: "gender"
        },
        {
          value: "dob"
        },
        {
          value: "address"
        },
        {
          value: "city"
        },
        {
          value: "district"
        },
        {
          value: "postal_code"
        },
        {
          value: "state"
        },
        {
          value: "country"
        },
        {
          value: "pan_no"
        },
        {
          value: "phone"
        },
        {
          value: "email"
        },
        {
          value: "referred_by"
        },
        {
          value: "transaction_id"
        }
      ]
    };
  },
  created() {
    //initialize fetchDonation function
    this.fetchDonation();
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    }
  },
  methods: {
    // Function to Fetch Data from Donation Single View API
    fetchDonation() {
      ApiService.setHeader();
      ApiService.get("/donationview/" + this.$route.params.donationId).then(
        response => {
          // JSON responses are automatically parsed.
          this.donations = response.data;
          var donationDetails = this.donations.donationDetails;
          this.receiptId = donationDetails[0].receipt_id;
        }
      );
    },
    //Get back to donations list page on click back button
    backToDonations: function() {
      //redirect to donations page
      this.$router.push("/donations");
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    //Downloading receipt
    receipt() {
      //  const submitButton = this.$refs["receipt-spinner"];
      //   submitButton.classList.add(
      //    "disabled"
      //   );

      ApiService.getPDF("userGetReceipt", this.receiptId).then(response => {
        var file = new Blob([response.data], {
          type: "application/pdf"
        });
        var filename = this.receiptId + ".pdf";
        const pdfData = window.URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = pdfData;
        link.download = filename;
        link.click();
        //  submitButton.classList.remove(
        //     "spinner",
        //     "spinner-light",
        //     "spinner-right",
        //     "disabled"
        //   );
      });
    },

    // showdownloading() {
    //     const submitButton = this.$refs["receipt-spinner"];
    //   submitButton.classList.add(
    //     "disabled"
    //   );
    // },

    //Resending email to donor
    resend() {
      ApiService.get("/receiptEmail", this.receiptId);
    },

    //Downloading decalration
    declaration() {
      //ApiService.setHeader();
      ApiService.getPDF("getSelfDeclaration", this.receiptId).then(response => {
        var file = new Blob([response.data], {
          type: "application/pdf"
        });
        var filename = this.receiptId + "-declartion.pdf";
        const pdfData = window.URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = pdfData;
        link.download = filename;
        link.click();
      });
    }
  }
};
</script>

<style scoped>
.thankyou__container {
  max-width: 55%;
}

@media screen and (max-width: 600px) {
  .thankyou__container {
    max-width: 100%;
  }
}
</style>
